import React, { useCallback, useMemo, useEffect } from 'react';
import * as T from './types';
import { useRootContext } from '../../contexts/Root';
import { Modal } from '@veneer/core';
import LazyMfeLoader from '../LazyMfeLoader';
import { AppFC } from '../../types/customReactTypes';
import { LazyMfeLoaderPropsType } from '../LazyMfeLoader/types';
import { MicrofrontendRouterAssetType } from '../../types/shell';
import { TenantPageLoaderPropsType } from '../TenantPageLoader';
import logger from '../../interfaces/logger';
import ShellProgressIndicator from '../ShellProgressIndicator/style';

const Renderer: AppFC<T.RootProps> = (props) => {
  const {
    microfrontendRouter,
    localizationResources,
    stack,
    store,
    applicationStatusManager
  } = useRootContext();
  const { content, layout, modalContent } =
    microfrontendRouter.useReactHook(React);
  const userOnboardingPath =
    store?.state?.manifest?.services?.onboarding?.userOnboardingPath;
  const contentPath = content?.path;
  const backgroundColor =
    store?.state?.manifest?.navigation?.stylization?.backgroundColor ||
    '#FFFFFF';

  const mfeWrapperStyle: React.CSSProperties = useMemo(
    () => ({
      background: backgroundColor,
      minHeight: '100vh',
      maxHeight: '100vh',
      minWidth: '100vw',
      maxWidth: '100vw'
    }),
    [backgroundColor]
  );

  const isUserOnboardingPath = useMemo(() => {
    if (userOnboardingPath) {
      const contentPathArray = Array.isArray(contentPath)
        ? contentPath
        : [contentPath];

      return contentPathArray.some((path) => path === userOnboardingPath);
    }

    return false;
  }, [contentPath, userOnboardingPath]);

  const lazyLoadModalResumeSession = useCallback(async (): Promise<any> => {
    return import('../ModalResumeSession');
  }, []);

  const loadDefaultOnboardingError = content?.loadDefaultOnboardingError;

  useEffect(() => {
    logger?.log?.('nav-renderer: ', {
      loadDefaultOnboardingError,
      isUserOnboardingPath,
      content,
      layout,
      modalContent
    });
  }, [
    content,
    isUserOnboardingPath,
    layout,
    loadDefaultOnboardingError,
    modalContent
  ]);

  useEffect(() => {
    if (loadDefaultOnboardingError) {
      applicationStatusManager?.showErrorPage?.({
        message: {
          label:
            'Please click on retry, if it still not work you may need to restart it'
        },
        buttons: {
          list: [
            {
              label: 'Retry',
              onClick: async () => {
                await content?.rootProperties?.retryUpdateOnboardingSession?.();
              }
            },
            {
              label: 'Exit Setup',
              onClick: async () => {
                await content?.rootProperties?.closeOnboardingSession?.();
              }
            }
          ]
        }
      });

      return () => applicationStatusManager?.hideAll?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadDefaultOnboardingError]);

  if (loadDefaultOnboardingError) {
    return null;
  }

  return (
    <ConditionalTenantPageLoader
      public={!!content?.public}
      isUserOnboardingPath={isUserOnboardingPath}
      isPathRoute={!!content?.isPathRoute}
      mfeWrapperStyle={mfeWrapperStyle}
      {...props}
    >
      <>
        {modalContent?.showResumeSessionModal ? (
          <LazyMfeLoader
            {...props}
            rootProperties={modalContent?.rootProperties}
            key={modalContent?.key}
            properties={modalContent?.properties}
            assetImportCallback={lazyLoadModalResumeSession}
          />
        ) : (
          <Modal
            show={!!modalContent?.enable}
            align="center"
            // closeButton
            // onClose={() => fallback?.clearFallbackState?.()}
          >
            <div
              style={{ maxHeight: '75vh', height: '75vh', overflow: 'auto' }}
            >
              {modalContent?.enable && (
                <LazyMfeLoader
                  {...props}
                  rootProperties={modalContent?.rootProperties}
                  key={modalContent?.key}
                  assetReference={modalContent?.assetReference}
                  properties={modalContent?.properties}
                />
              )}
            </div>
          </Modal>
        )}

        <LayoutHandler
          enable={!!layout?.enable}
          key={layout?.key}
          progressIndicator="linear"
          assetReference={layout?.assetReference}
          properties={layout?.properties}
          localizationResources={localizationResources}
          useDefaultRouteLayout={layout?.useDefaultRouteLayout as boolean}
          useDefaultOnboardingLayout={
            layout?.useDefaultOnboardingLayout as boolean
          }
          content={content}
          stack={stack}
          mfeWrapperStyle={mfeWrapperStyle}
        >
          {(injectedChildrenProperties, mfeWrapperStyle) => {
            return content?.enable ? (
              <LazyMfeLoader
                {...props}
                rootProperties={{
                  ...content?.rootProperties,
                  ...injectedChildrenProperties
                }}
                key={content?.key}
                assetReference={content?.assetReference}
                properties={content?.properties}
                mfeWrapperStyle={mfeWrapperStyle}
              />
            ) : (
              <div style={mfeWrapperStyle}>
                <ShellProgressIndicator
                  appearance="circular"
                  className="jshell-progress-indicator"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: 'calc(100vh - 144px)' // 144px = 68px header + 76px footer
                  }}
                />
              </div>
            );
          }}
        </LayoutHandler>
      </>
    </ConditionalTenantPageLoader>
  );
};

function ConditionalTenantPageLoader({
  public: isPublic,
  isPathRoute,
  children,
  isUserOnboardingPath,
  mfeWrapperStyle,
  ...tenantPageLoaderProps
}: TenantPageLoaderPropsType & {
  public?: boolean;
  children?: any;
  isPathRoute?: boolean;
  isUserOnboardingPath?: boolean;
  mfeWrapperStyle?: React.CSSProperties;
}) {
  const { tenantHandlerInterface } = useRootContext();

  const lazyLoadTenantSelector = useCallback(async (): Promise<any> => {
    return import('../TenantPageLoader');
  }, []);

  const useTenantPageLoader = useMemo(() => {
    return (
      tenantHandlerInterface?.isEnabled() &&
      isPathRoute &&
      !isPublic &&
      !isUserOnboardingPath
    );
  }, [isPathRoute, isPublic, isUserOnboardingPath, tenantHandlerInterface]);

  useEffect(() => {
    logger?.log?.('nav-tenant-selector-check: ', { useTenantPageLoader });
  }, [useTenantPageLoader]);

  if (useTenantPageLoader) {
    return (
      <LazyMfeLoader
        {...tenantPageLoaderProps}
        mfeWrapperStyle={mfeWrapperStyle}
        assetImportCallback={lazyLoadTenantSelector}
      >
        {children}
      </LazyMfeLoader>
    );
  }

  return children;
}

function LayoutHandler({
  enable,
  children,
  useDefaultOnboardingLayout,
  useDefaultRouteLayout,
  mfeWrapperStyle,
  content,
  ...props
}: LazyMfeLoaderPropsType & {
  children: (
    props: Record<string, any>,
    mfeWrapperStyle?: React.CSSProperties
  ) => any;
  enable: boolean;
  useDefaultRouteLayout?: boolean;
  useDefaultOnboardingLayout?: boolean;
  content?: MicrofrontendRouterAssetType;
}) {
  const layoutAssetReference = props?.assetReference;

  const lazyLoadHeaderAndFooter = useCallback(async (): Promise<any> => {
    return import('../../layouts/HeaderAndFooter');
  }, []);

  const lazyLoadSystemLayout = useCallback(async (): Promise<any> => {
    return import('../../layouts/DefaultSystem/components/SystemLayout');
  }, []);

  useEffect(() => {
    logger?.log?.('nav-layout-check: ', {
      useDefaultOnboardingLayout,
      useDefaultRouteLayout,
      enable,
      layoutAssetReference
    });
  }, [
    enable,
    layoutAssetReference,
    useDefaultOnboardingLayout,
    useDefaultRouteLayout
  ]);

  if (useDefaultOnboardingLayout) {
    return (
      <LazyMfeLoader
        content={content}
        mfeWrapperStyle={mfeWrapperStyle}
        assetImportCallback={lazyLoadHeaderAndFooter}
      />
    );
  } else if (useDefaultRouteLayout) {
    return (
      <LazyMfeLoader
        currentPath={content}
        mfeWrapperStyle={mfeWrapperStyle}
        assetImportCallback={lazyLoadSystemLayout}
      >
        {children}
      </LazyMfeLoader>
    );
  } else if (enable && layoutAssetReference) {
    return (
      <LazyMfeLoader
        {...props}
        mfeWrapperStyle={mfeWrapperStyle}
      >
        {children}
      </LazyMfeLoader>
    );
  }

  return (
    <>
      {typeof children === 'function'
        ? children({}, mfeWrapperStyle)
        : children}
    </>
  );
}

export default Renderer;
