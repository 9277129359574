import React, { useEffect, useState } from 'react';
import { useRootContext } from '../../contexts/Root';
import MfeNotFound from '../MfeNotFound';
import useAssetImport from './hooks/useAssetImport';
import * as T from './types';
import logger from '../../interfaces/logger';

const LazyMfeLoader: React.FC<T.LazyMfeLoaderPropsType> = ({
  children,
  rootProperties,
  showLoader,
  onMount,
  mfeWrapperStyle,
  ...props
}) => {
  const { componentProps, isLoading, mfe } = useAssetImport(props);
  const { store, session, stack } = useRootContext();
  const [mfeKey, setMfeKey] = useState(0);

  useEffect(() => {
    onMount?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    logger?.log?.('nav-renderer-LazyMfeLoader: ', {
      isLoading,
      showLoader,
      haveMfeDefault: mfe?.default
    });
  }, [isLoading, mfe?.default, showLoader]);

  useEffect(() => {
    const observerId = store.generateId();
    store.addObserver(observerId, (state, prevState) => {
      if (
        state?.manifest !== prevState?.manifest &&
        JSON.stringify(state?.manifest) !== JSON.stringify(prevState?.manifest)
      ) {
        setMfeKey(mfeKey + 1);
      }
    });
    return () => store.removeObserver(observerId);
  }, [store, mfeKey]);

  if (isLoading || showLoader) {
    return null;
  } else if (mfe?.default) {
    return (
      <div style={mfeWrapperStyle}>
        <mfe.default
          {...{
            ...rootProperties,
            session,
            stack,
            ...componentProps,
            children
          }}
          key={mfeKey}
        />
      </div>
    );
  } else if (children) {
    return <>{children === 'function' ? (children as any)({}) : children}</>;
  } else {
    return <MfeNotFound />;
  }
};

export default LazyMfeLoader;
